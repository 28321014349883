import { useEffect, useState } from 'react'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
import { FormControl, TextField } from '@mui/material';


import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import axios from '../../api/axios';
import Axios from 'axios';
import { Loading } from '../LoadingPage/Loading';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

const LoginPage = () => {
	const { googlelogin, TokenSave, getUser } = useAuthContext();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState([]);
	const [security, setSecurity] = useState(null)
	const navigate = useNavigate()

	const onChange = (e) => {
		setSecurity(e)
	}
	//google login samuelamin 54112919
	const login = useGoogleLogin({
		onSuccess: (codeResponse) => handle_google_login(codeResponse),
		onError: (error) => console.log('Login Failed:', error)
	});


	const handle_google_login = async (codeResponse) => {
		await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
			headers: {
				Authorization: `Bearer ${codeResponse.access_token}`,
				Accept: 'application/json'
			}
		})
			.then((res) => {
				console.log(res.data);
				const google_email = res.data.email
				const google_id = res.data.id
				const google_name = res.data.name
				googlelogin({ google_email, google_id, google_name })
				setLoading(true)
			})
			.catch((err) => setLoading(false));
	}

	const handleLogin = async (event) => {
		event.preventDefault()
		setLoading(true)
		setErrors([])
		try {
			const response = await axios.post('/sign-in', { email, password })
			TokenSave('access_token', response.data.token)
			getUser()
			navigate('/')
		} catch (e) {
			console.log(e);
			setLoading(false)
			setErrors(e.response.data.errors)
			Swal.fire({
				title: "Sorry",
				text: Object.values(e.response.data.errors)[0],
				icon: "error",
			})

		}

	}

	return loading ? <Loading /> :
		<form className="row flex-center" onSubmit={handleLogin}>
			<div className="row flex-start">
			<img src={process.env.REACT_APP_IMAGE_PATH + (localStorage.getItem('web_img') ?? '')} className='w-200px w-md-50 w-xl-200px me-3' alt="" />
			</div>
			{/* <h3 className='text-center text_liltel_dark f-boold_700 Inter'>Sign in</h3> */}
			
			<div className='fv-row col-11 mt-4' >
				<FormControl fullWidth>
					<TextField id="outlined-basic" label='Name'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						variant="outlined" autoComplete='off'
						type='email'
					/>
				</FormControl>
				{errors.Email &&
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{errors.Email}</span>
						</div>
					</div>
				}
			</div>
			<div className='fv-row col-11 mt-4'>
				<FormControl fullWidth>
					<TextField id="outlined-basic" label='Password *'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						variant="outlined" autoComplete='off'
						type='password'
					/>
				</FormControl>
				{errors.password &&
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{errors.password}</span>
						</div>
					</div>
				}
			</div>
			{/* <div className='fv-row col-6 px-5 mt-4'>
				<div className="text-muted text-start fw-semibold fs-6">
					<Link to="/Register" className=" ms-3 link-primary fw-bold">Create an Account</Link>.
				</div>
			</div>
			<div className='fv-row col-6 mt-4 px-5'>
				<div className="text-muted text-end fw-semibold fs-6">
					<Link to="/forgot-password" className=" ms-3 link-primary fw-bold">Forgot Password</Link>.
				</div>
			</div> */}

			<div className="my-3 col-11">
				<ReCAPTCHA sitekey="6LdLNhAmAAAAANFJxdmha-Pz3IK4L1XHa9S-R4l_" onChange={onChange} />
			</div>
			
			<div className='fv-row col-11 mt-4'>
				<button type='submit' className="login  w-100" disabled={security == null}>
					{!loading && <span className='indicator-label'>Sign In</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							Please wait...{' '}
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
			</div>
			<div className='fv-row col-11 mt-4'>
				<button type='button' onClick={() => login()} disabled={security == null} className="login2 w-100  d-flex flex-center">
					<img src={google_icon} width='20' className='me-3' alt="" />
					{!loading && <span className='indicator-label'>Sign In with Google</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							Please wait...{' '}
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
			</div>

		</form>
}



const Login = () => {
	return <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
		<LoginPage />
	</GoogleOAuthProvider>
}


export default Login