import  { useEffect, useState } from 'react'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
import { FormControl, TextField } from '@mui/material';
//google login 
import logo from '../../../_metronic/assets/Wrkbee/User/logo.png'
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import axios from '../../api/axios';
import Axios from 'axios';
import { Loading } from '../LoadingPage/Loading';
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPasswordPage = () => {
const {googlelogin ,csrf ,getUser} = useAuthContext();
const [email , setEmail] = useState('');
const [status , setStatus] = useState('');
const [loading, setLoading] = useState(false)
const [errors , setErrors] = useState([]);
const navigate = useNavigate()
const [security , setSecurity] = useState(null)


const login = useGoogleLogin({
	onSuccess: (codeResponse) => handle_google_login(codeResponse),
	onError: (error) => console.log('Login Failed:', error)
});
const handle_google_login =async(codeResponse)=>{
	await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
			headers: {
				Authorization: `Bearer ${codeResponse.access_token}`,
				Accept: 'application/json'
			}
		})
		.then((res) => {
			console.log(res.data);
			const google_email = res.data.email
			const google_id = res.data.id
			const google_name = res.data.name
			googlelogin({google_email , google_id , google_name })
			setLoading(true)
		})
	.catch((err) => setLoading(false));
}

const handleLogin = async(event)=>{
	event.preventDefault()
	csrf()
	setLoading(true)
	setErrors([])
	try{
		
		const response = await axios.post('/forgot-password',{email});
		setStatus(response.data.status)
		setLoading(false)
	}catch(e){
		if(e.response.status == 422)
		{
			setLoading(false)
			setErrors(e.response.data.errors)
		}
	}

}
const onChange =(e)=>{
	setSecurity(e)
}
  return (
	<>

	
	<div className='row ' style={{height:'100vh'}}>
		
		<div className="pb-5 col-lg-5 col-sm-12 d-flex flex-center " style={{backgroundColor:'#f1f1f1',justifyContent:'center'}}>
			<div className=''  >
				<img src={logo}  className='ms-5' width='300' alt="" />
				<h3 className='text-center text_liltel_dark f-boold_700 Inter'>Welcome to Wrkbee</h3>
				<h5 className='text-center mt-4 text_liltel_dark f-boold_700 Inter'>Managing your business has never been easier!</h5>
			</div>
		</div>
		<div className="col-lg-7 pb-5 col-sm-12 d-flex flex-center" style={{backgroundColor:'white',justifyContent:'center'}}>
			<form onSubmit={handleLogin} className='col-lg-8 col-sm-12 '  >
				{status && <div className='btn btn-success text-center  col-12 mb-5 mt-3'> <h6 className='text-white mt-2'>{status}</h6> </div>}
				{errors.email && <div className='btn btn-success text-center  col-12 mb-5 mt-3'> <h6 className='text-white mt-2'>We have emailed your password reset link!</h6> </div>}
				<div className='fv-row col-12'>
					<h3 className='text-center text_liltel_dark f-boold_700 Inter'>Reset Password</h3>
					<p className='text-center text-gray-400'>New Here? <Link to='/Register' className='text-center mt-4 text_liltel_dark f-boold_700 Inter'>Create an Account</Link></p>
					<div className='mt-3'>
						<h6 className='text-aling-start text_liltel_dark f-boold_700 Inter'>Email</h6>
						<FormControl fullWidth>
							<TextField 
								sx={{backgroundColor:'#F5F8FA'}}
								value={email}
								onChange={(e)=>setEmail(e.target.value)}
								type='email'
								id="outlined-start-adornment"
								
							/>
							</FormControl>
							
						
					</div>
					<div className="my-3">
						<ReCAPTCHA   sitekey="6LdLNhAmAAAAANFJxdmha-Pz3IK4L1XHa9S-R4l_" onChange={onChange}/>
					</div>
					<div className="row ">
						<div className="col-lg-4 col-sm-12 mt-3">
							<button type='submit' disabled={security == null} className="login2 col-12 f-boold_700 Inter">
								{!loading && <span className='indicator-label'>Reset Password</span>}
								{loading && (
								<span className='indicator-progress' style={{display: 'block'}}>
									Please wait...{' '}
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
								)}
							</button>
						</div> 
						<div className="col-lg-8 col-sm-12 mt-3">
								<button type='button' disabled={security == null}  onClick={() => login()} className="login2 col-12 f-boold_700 d-flex flex-center Inter">
									<img src={google_icon} width='20' className='me-3' alt="" />
									{!loading && <span className='indicator-label'>Sign In with Google</span>}
									{loading && (
									<span className='indicator-progress' style={{display: 'block'}}>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle '></span>
									</span>
									)}
								</button>
						</div> 
					</div>
				</div>

			</form>
		</div>
	</div>
	</>
  )
}
const ForgotPassword = () => {
	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
			<ForgotPasswordPage />
		</GoogleOAuthProvider>
	)
}
export default ForgotPassword