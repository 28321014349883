import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { pending_order } = useAuthContext()
  return (
    <div>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Application' })}  </span>
        </div>
      </div>



      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Countries' })}
        to='/Countries'
        icon='/media/icons/duotune/maps/map008.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Currencies' })}
        to='/Currencies'
        icon='/media/icons/duotune/finance/fin010.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub to='/Levels' title={intl.formatMessage({ id: 'Menu.Levels' })} icon='/media/icons/duotune/general/gen003.svg' hasBullet={false}>
        <SidebarMenuItem to='/Levels/Levels' title={intl.formatMessage({ id: 'Menu.Levels' })} hasBullet={true} />
        <SidebarMenuItem to='/Levels/Users-Requests' title={intl.formatMessage({ id: 'Menu.LevelsRequests' })} hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Users' })}
        to='/Users/Users'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-layers'
      />



      <SidebarMenuItemWithSub to='/WebDetails' title={intl.formatMessage({ id: 'Menu.WebDetails' })} icon='/media/icons/duotune/electronics/elc001.svg' hasBullet={false}>
        <SidebarMenuItem to='/WebDetails/Banners' title={intl.formatMessage({ id: 'Menu.Banners' })} hasBullet={true} />
        <SidebarMenuItem to='/WebDetails/HomeSections' title={intl.formatMessage({ id: 'Menu.HomeSections' })} hasBullet={true} />
        <SidebarMenuItem to='/WebDetails/HomeSliders' title={intl.formatMessage({ id: 'Menu.HomeSliders' })} hasBullet={true} />
        <SidebarMenuItem to='/WebDetails/WebSiteDetails' title={intl.formatMessage({ id: 'Menu.WebSiteDetails' })} hasBullet={true} />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Integerations' })}  </span>
        </div>
      </div>

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Payments' })}
        to='/Payments'
        icon='/media/icons/duotune/finance/fin008.svg'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Suppliers' })}
        to='/Suppliers'
        icon='/media/icons/duotune/general/gen001.svg'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'> {intl.formatMessage({ id: 'Menu.Products' })}  </span>
        </div>
      </div>


      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Categories' })}
        to='/Categories'
        icon='/media/icons/duotune/abstract/abs015.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Discounts' })}
        to='/Discounts/Discounts'
        icon='/media/icons/duotune/ecommerce/ecm003.svg'
        fontIcon='bi-layers'
      />


      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.Orders' })}
        to='/Orders'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        fontIcon='bi-layers'
      />
    

      <SidebarMenuItem
        title={intl.formatMessage({ id: 'Menu.PendingOrders' })}
        to='/PendingOrders'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        fontIcon='bi-layers'
        count={pending_order.toString()}
      />
      
      <SidebarMenuItemWithSub to='/Products' title={intl.formatMessage({ id: 'Menu.Products' })} icon='/media/icons/duotune/ecommerce/ecm007.svg' hasBullet={false}>
        <SidebarMenuItem to='/Products/Products' title={intl.formatMessage({ id: 'Menu.Products' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/Shortcomings' title={intl.formatMessage({ id: 'Menu.Shortcomings' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/NewArrivals' title={intl.formatMessage({ id: 'Menu.NewArrivals' })} hasBullet={true} />
        <SidebarMenuItem to='/Products/TopSelling' title={intl.formatMessage({ id: 'Menu.TopSelling' })} hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/Stocks' title={intl.formatMessage({ id: 'Menu.Stocks' })} icon='/media/icons/duotune/abstract/abs007.svg' hasBullet={false}>
        <SidebarMenuItem to='/Stocks/Add-Product-Stocks' title={intl.formatMessage({ id: 'Menu.AddStock' })} hasBullet={true} />
        <SidebarMenuItem to='/Stocks/Check-Stock' title={intl.formatMessage({ id: 'Menu.CheckStock' })} hasBullet={true} />
      </SidebarMenuItemWithSub>


    </div>
  )
}

export { SidebarMenuMain }
