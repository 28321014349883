import  { useEffect, useState } from 'react'
import { Link, useNavigate,  useParams , useSearchParams  } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import logo from '../../../_metronic/assets/Wrkbee/User/logo.png'
import google_icon from '../../../_metronic/assets/Wrkbee/User/google.png'
import axios from '../../api/axios';


const ResetPassword = () => {
const { csrf ,getUser} = useAuthContext();
const [name , setName] = useState('');
const [password , setPassword] = useState('');
const [email , setEmail] = useState('');
const [phone , setPhone] = useState('');
const [password_confirmation , setPasswordConfirmation] = useState('');
const [loading, setLoading] = useState(false)
const [errors , setErrors] = useState([]);
const navigate = useNavigate()
const {token} = useParams();
const [searchParams] = useSearchParams();

useEffect(()=>{
    setEmail(searchParams.get('email'))
},[])

const handleResetPassword = async(event)=>{
	event.preventDefault()
	csrf()
	setLoading(true)
	setErrors([])
	try{
        await axios.post('/reset-password',{ email , token , password , password_confirmation});
		getUser()
		navigate('/')
	}catch(e){
		if(e.response.status == 422)
		{
			setLoading(false)
			setErrors(e.response.data.errors)
		}
	}

}

  return (
    <div className=" d-flex flex-center" >
      <div className='col-lg-6 col-sm-12'  style={{minHeight:'100vh',justifyContent:'center'}}>
        <div className="d-flex justify-content-center mt-5">
           <img src={logo}  style={{width:'40%'}} alt="" /> 
        </div>
        <div className=" ps-5 pe-5 py-3  " style={{backgroundColor:'white'}}>
          
          <form onSubmit={handleResetPassword} >
            <div className='fv-row col-12'>
            <h3 className='text-center text_liltel_dark f-boold_700 Inter'>Reset Password</h3>


              <div className='mt-3'>
                <h6 className='text-aling-start text_liltel_dark f-boold_700 Inter'>Email</h6>
                <FormControl fullWidth>
                  <TextField  
                    sx={{backgroundColor:'#F5F8FA'}}
                    value={email}
                    type='email'
                    id="outlined-start-adornment"
                    placeholder='Email'
                  />
                  </FormControl>
                  
                  {errors.email &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                      <span role='alert'>{errors.email}</span>
                      </div>
                  </div>
                }
              </div>

              
              <div className='mt-4'>
                 <h6 className='text-aling-start mb-0 text_liltel_dark f-boold_700 pb-2 Inter'>Password</h6>
                  <FormControl fullWidth>
                  <TextField 
                    sx={{backgroundColor:'#F5F8FA'}}
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    type='password'
                    id="outlined-start-adornmentpassword"
                    placeholder=' Password '
                  />
                  </FormControl>
                  {errors.password &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                      <span role='alert'>{errors.password}</span>
                      </div>
                  </div>
                }
              </div>

              <div className='mt-4'>
                 <h6 className='text-aling-start mb-0 text_liltel_dark f-boold_700 pb-2 Inter'>Password Confirmation</h6>
                  <FormControl fullWidth>
                  <TextField 
                    sx={{backgroundColor:'#F5F8FA'}}
                    value={password_confirmation}
                    onChange={(e)=>setPasswordConfirmation(e.target.value)}
                    type='password'
                    id="outlined-start-adornmentpassword"
                    placeholder=' Password Confirmation'
                  />
                  </FormControl>
                  {errors.password_confirmation &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                      <span role='alert'>{errors.password_confirmation}</span>
                      </div>
                  </div>
                }
              </div>


              <div className="row  flex-center">
                <div className="col-lg-6 col-sm-12 mt-3">
                  <button type='submit' className="login2 col-12 f-boold_700 Inter">
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )}
                  </button>
                </div> 
                
                  
              </div>
            </div>

          </form>
        </div>
      </div>
  </div>
  )
}
export default ResetPassword