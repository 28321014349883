import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { FaCopy } from "react-icons/fa";

const SuppliersHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <Link to='/Add-Suppliers' type='button' className='btn btn-primary' >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Suppliers.Create' })}
        </Link>
      </div>

    </div>
  );
  const get_data = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/admin-suppliers')
    console.log(data);
    setItems(data)
    setLoading(false)

  }
  const edit_status = async (value, id) => {
    let endpoint;
    endpoint = `/change-suppliers-status/${id}/${value}`;
    try {
      await axiosInstance.get(endpoint);
      get_data()
    } catch (error) {
      console.error(error);
    }
  }
  const success =()=>{
    Swal.fire({
      icon: 'success',
      text: intl.formatMessage({id: 'Table.text_copy'}),
      showConfirmButton: false,
      timer: 1500
    });
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const { data } = await axiosInstance.delete(`/admin-suppliers/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });

  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          #{row.original.id}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.Name' }),
      accessor: 'name',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.name}
        </div>
      ),
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: row => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          <input
            className='form-check-input'
            type='checkbox'
            value={row.row.original.status}
            name='notifications'
            defaultChecked={row.row.original.status}
            onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.app_key' }), accessor: 'app_key',
      Cell: row => (
        <div className=" w-100 flex-center">
          <button onClick={(e)=>[navigator.clipboard.writeText(row.row.original.app_key) , success()]} type='button' className='btn w-100 btn-primary' >
            <FaCopy className='me-2' />
            {intl.formatMessage({ id: 'Table.Copy' })}
           </button>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.app_screet' }), accessor: 'app_screet',
      Cell: row => (
        <div className=" w-100 flex-center">
          <button onClick={(e)=>[navigator.clipboard.writeText(row.row.original.app_screet) , success()]} type='button' className='btn w-100 btn-primary' >
            <FaCopy className='me-2' />
            {intl.formatMessage({ id: 'Table.Copy' })}
           </button>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: row => (
       <div>

       <div className="d-flex flex-center my-2">
          <Link to={`/Edit-Suppliers/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </Link>
          <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2 w-100  btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
        </div>
       <div className="d-flex flex-center">
          <Link  to={`/Suppliers/${row.row.original.value}/get-Categories/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Menu.Categories' })}  </Link>
          <Link  to={`/Suppliers/${row.row.original.value}/Reports/${row.row.original.value}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Menu.Reports' })}  </Link>
        </div>
       </div>
      ),
    },
  ];

  useEffect(() => {
    get_data()
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Suppliers' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Suppliers' })}</li>
          </ul>
        </div>
      </div>
      {Items !== null && !loading ?
        <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }

    </motion.nav>
  )
}
//get-banners
export default SuppliersHome