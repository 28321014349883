import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';

export const OrderDetails = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const { id } = useParams()


    const get_data = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get(`/admin-orders/${id}`)
        setData(data)
        console.log(data.items);
        setLoading(false)
    }

    const TruncateString = ({ text }) => {
        const truncatedText = text ? text.slice(0, 50) : '';
        return truncatedText;
    };

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }),
            accessor: 'item.id',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    # {row.original.item.id}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'item.name_en',
            Cell: row => (
                <div className="w-300 flex-center fs-12 f-bold">
                    <div>
                        <TruncateString text={row.row.original.item.name_en} />
                    </div>
                    <div>
                        <TruncateString text={row.row.original.item.name_ar} />
                    </div>
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.File' }), accessor: 'item.img',
            Cell: row => (
                <div className="symbol symbol-50px ">
                    <img src={row.row.original.item.img ? process.env.REACT_APP_IMAGE_PATH + row.row.original.item.img : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Count' }),
            accessor: 'count',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.count}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Total' }),
            accessor: 'total',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.total}
                </div>
            )
        },

    ];

    useEffect(() => {
        get_data()

    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.OrderDetails' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Orders' })}</li>
                    </ul>
                </div>
                <div>
                    <Link to='/Orders' type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </Link>
                </div>
            </div>
            {data && !loading ?
                <>
                    <div className="card my-4">
                        <div className='card-body p-9'>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.OrderID' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data.order.id}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.User' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data.order.user.name}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Total' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data.order.total}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.payment_type' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data.order.payment_type}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Date' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data.order.created_at}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Status' })}</label>
                                <div className='col-lg-8'>
                                    {data.order.status === 'failed' &&
                                        <span className="badge badge-light-danger">{data.order.status}</span>
                                    }

                                    {data.order.status === 'pending' &&
                                        <span className="badge badge-light-warning">{data.order.status}</span>
                                    }

                                    {data.order.status === 'completed' &&
                                        <span className="badge badge-light-success">{data.order.status}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTable columns={columns} data={data.items} customComponent={<></>} />
                </>
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>
    )
}
