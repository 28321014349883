import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { FaCopy } from "react-icons/fa";

const Shortcomings = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [Items, setItems] = useState(null)
    const CustomDiv = () => (
        <div className="row flex-end w-100">
        </div>
    );

    const TruncateString = ({ text }) => {
        const truncatedText = text ? text.slice(0, 50) : '';
        return truncatedText;
    };

    const get_data = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/admin-Shortcomings')
        setItems(data)
        setLoading(false)

    }

 
    const sync = (id) => {
        Swal.fire({
            title: 'LookUp',
            text: "You won't be able to revert ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, sync it!',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const { data } = await axiosInstance.get(`/admin-Shortcomings/${id}`);
                    if (!data || data.length === 0) {
                        throw new Error(JSON.stringify(data));
                    }
                    get_data()
                } catch (error) {
                    Swal.showValidationMessage(`
                  Request failed: ${error}
              `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Done!",
                    icon: "success"
                });
            }
        });

    }

 

    const edit_status = async (value, id) => {
        let endpoint;
        endpoint = `/change-item-status/${id}/${value}`;
        try {
            await axiosInstance.get(endpoint);
            get_data()
        } catch (error) {
            console.error(error);
        }
    }

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
            Cell: row => (
                <div className="w-100 flex-center ">
                    <div>
                        # {row.row.original.id}
                    </div>
                    <div>
                        # {row.row.original.product_id}
                    </div>
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Supplier' }), accessor: 'type',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.type ?? '---'}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'name_en',
            Cell: row => (
                <div className="w-300 flex-center fs-12 f-bold">
                    <div>
                        <TruncateString  text={row.row.original.name_en} />
                    </div>
                    <div>
                        <TruncateString text={row.row.original.name_ar} />
                    </div>
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.File' }), accessor: 'File',
            Cell: row => (
                <div className="symbol symbol-50px ">
                    <img src={row.row.original.img ? process.env.REACT_APP_IMAGE_PATH + row.row.original.img : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
                </div>
            )
        },
        {
            Header: 'enable',
            accessor: 'status',
            Cell: row => (
                <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        value={row.row.original.status}
                        name='notifications'
                        defaultChecked={row.row.original.status}
                        onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
                    />
                </div>
            ),
        },
        {
            Header: intl.formatMessage({ id: 'Table.Available' }), accessor: 'available',
            Cell: row => (
                <div className="w-100 flex-center">
                    {row.row.original.available ?
                        <span className="badge badge-light-success">{intl.formatMessage({ id: 'Table.Available' })}</span>
                        :
                        <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Table.notAvailable' })}</span>
                    }
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.General_Inofrmation' }), accessor: 'General_Inofrmation',
            Cell: row => (
              <div className=" flex-center w-200" >
                <div className="row flex-between">
                  <div className="col-8 text-start">
                    <div className='my-2 fs-12'>
                      {intl.formatMessage({ id: 'Table.Price' })}
                    </div>
                    <div className='my-2 fs-12'>
                      {intl.formatMessage({ id: 'Table.Stock' })}
                    </div>
                    <div className='my-2 fs-12'>
                      {intl.formatMessage({ id: 'Table.MiniStock' })}
                    </div>
                    <div className='my-2 fs-12'>
                      {intl.formatMessage({ id: 'Table.SalePriority' })}
                    </div>
                  </div>
                  <div className="col-4 text-start">
                    <div className='my-2 fs-12'>
                      {': ' + row.row.original.price.toFixed(1)}
                    </div>
                    <div className='my-2 fs-12'>
                      {': ' + row.row.original.stock}
                    </div>
                    <div className='my-2 fs-12'>
                      {': ' + row.row.original.min_stock}
                    </div>
                    <div className='my-2 fs-12'>
                      {': ' + row.row.original.sale_priority}
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
      
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center my-2">
                    <button onClick={(e) => sync(row.row.original.id)} className='btn me-2 w-100  btn-light-dark'> {intl.formatMessage({ id: 'Table.Sync' })}  </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        get_data()
    }, [])
    
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Shortcomings' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Products' })}</li>
                    </ul>
                </div>
            </div>
            {Items !== null && !loading ?
                <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>
    )
}
//get-banners
export default Shortcomings