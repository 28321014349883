import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'

const BinanceProducts = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')
  const [loading2, setLoading2] = useState(false);
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const [imported, setImported] = useState([]);
  const [cats, setCats] = useState([]);
  const [cat_id, setCat_id] = useState(null);
  const [errors, setErrors] = useState([]);

  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <Link to='/Suppliers/binance/add-Products' type='button' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Products.Create' })}
        </Link>
      </div>
    </div>
  );

  const CustomDiv2 = () => (
    <div className="row flex-end w-100">
      <div className="col-6">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{intl.formatMessage({ id: 'Form.Select_cateory' })} </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={intl.formatMessage({ id: 'Form.Select_cateory' })}
            onChange={(e) => setCat_id(e.target.value)}
            value={cat_id}
          >
            {cats.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );


  const deleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await axiosInstance.delete(`/admin-binance-products/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });
  }



  const handleChange = (event) => {
    const { value, name } = event.target;
    updateImportedItem(name, 'country_id', value);
  }
  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item);
    } else {
      remove_item(item);
    }
  };


  const add_item = (item) => {
    const rowsInput = {
      type: 'binance',
      id: item.id,
      name_en: item.name_en,
      name_ar: item.name_ar,
      price: item.price,
      country_id: ''
    };
    setImported((prevImported) => [...prevImported, rowsInput]);
  };

  const remove_item = (item) => {
    setImported((prevImported) => prevImported.filter(importedItem => importedItem.id !== item.id));
  };

  const updateImportedItem = (id, key, value) => {
    setImported((prevImported) =>
      prevImported.map((item) =>
        item.id === id ? { ...item, [key]: value } : item
      )
    );
  };


  const isChecked = (id) => {
    return imported.some(importedItem => importedItem.id === id);
  };

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
      Cell: row => (
        <div className="w-100 flex-center">
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => { handle_change(e, row.row.original) }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.File' }),
      accessor: 'img',
      Cell: ({ row }) => (
        <div className="symbol symbol-50px">
          <img src={row.original.img ? process.env.REACT_APP_IMAGE_PATH + row.original.img : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionEN' }),
      accessor: 'name_en',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.name_en}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionAR' }),
      accessor: 'name_ar',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.name_ar}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Cost' }),
      accessor: 'cost',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.cost}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Price' }),
      accessor: 'price',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.price}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.sale_priority' }),
      accessor: 'sale_priority',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.sale_priority}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.mini_stock' }),
      accessor: 'min_stock',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.min_stock}
        </div>
      )
    },


    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: ({ row }) => (
        <div className="d-flex flex-center">
          <Link to={`/Suppliers/binance/Edit-Products/${row.original.id}`} className='btn me-2 btn-light-primary'>
            {intl.formatMessage({ id: 'Table.Edit' })}
          </Link>
          <button onClick={() => deleteCategory(row.original.id)} className='btn me-2 btn-light-danger'>
            {intl.formatMessage({ id: 'Table.Delete' })}
          </button>
        </div>
      )
    }
  ];

  const columns2 = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
      Cell: row => (
        <div className="w-100 flex-center">
          # {row.row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'Description',
      Cell: row => (
        <div className="w-100 flex-center fs-12">
          <div>
            {row.row.original.name_en}
          </div>
          <div>
            {row.row.original.name_ar}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Price' }), accessor: 'price',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.price.toFixed(2)}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Country' }), accessor: 'Country',
      Cell: row => (
        <div className="w-100 flex-center">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{intl.formatMessage({ id: 'Form.Types' })} </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={intl.formatMessage({ id: 'Form.Types' })}
              name={row.row.original.id}
              onChange={handleChange}
              value={row.row.original.country_id}
            >
              <MenuItem value=''>
                {intl.formatMessage({ id: 'Form.empty' })}
              </MenuItem>
              {countries.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )
    },
  ];

  const save_btn_handler = async (event) => {
    setLoading2(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('data', JSON.stringify(imported));
    formData.append('cat_id', cat_id);
    try {
      await axiosInstance.post(`/admin-binance-items-import-from-cat`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading2(false)
        setErrors([])
        setImported([])
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading2(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const getData = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/admin-binance-products')
      console.log('Fetched Data:', data)
      setItems(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const get_countries = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/admin-countries')
    setCountries(data)
  }

  const get_cats = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/admin-cats')
    setCats(data)
  }

  useEffect(() => {
    getData()
    get_countries();
    get_cats();
  }, [])

  const filteredItems = items.filter(item =>
    item.name_en.toLowerCase().includes(search.toLowerCase()) ||
    item.name_ar.toLowerCase().includes(search.toLowerCase()) ||
    item.type.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            {intl.formatMessage({ id: 'Menu.Binance' })}
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Suppliers' })}
            </li>
          </ul>
        </div>
      </div>
      {imported.length > 0 &&
        <div className="row flex-between my-3">
          <div className="col-2">
            <button onClick={(e) => setStep(step - 1)} type='button' className='w-100 btn btn-secondary'>
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
              {intl.formatMessage({ id: 'Table.Back' })}
            </button>
          </div>
          <div className="col-2">
            {step === 1 &&
              <button onClick={(e) => setStep(step + 1)} type='button' className='w-100 btn btn-light-primary'>
                {intl.formatMessage({ id: 'Table.Next' })}
                <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2 me-0 ms-3' />
              </button>
            }
            {step === 2 &&
              <button onClick={save_btn_handler} type='button' className='w-100 btn btn-light-primary'>
                {!loading2 && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Save' })} </span>}
                {loading2 && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}

              </button>
            }
          </div>
        </div>
      }
      {step === 1 &&
        <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
      }
      {step === 2 &&
        <ReactTable columns={columns2} data={imported} customComponent={<CustomDiv2 />} />
      }
    </motion.nav>
  )
}

export default BinanceProducts
