import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import moment from 'moment';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable';
import ProductsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/ProductsAutoComplete';

export const UpdateMerchantProducts = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [user_value, setUSER_Value] = useState(null);
  const { id } = useParams()
  const [data, setData] = useState({
    type: 'fixed',
    percentage: 0,
    discount: 0,
    up_to: 0,
    user_id: id,
  });


  const types = [
    { id: 'fixed', name: 'Fixed' },
    { id: 'variable', name: 'Variable' },
  ]

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data');
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('item_id', user_value.id);
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/admin-merchants/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(`/Users/Edit-User-Discounts/${data.user_id}`)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    try {
      await axiosInstance.get(`/admin-merchants/${id}`).then(function (res) {
        const response = res.data
        setData({
          type: response.type,
          percentage: response.percentage ?? 0,
          discount: response.discount ?? 0,
          up_to: response.up_to ?? 0,
          user_id: response.user_id ?? 0,
        })
        setUSER_Value(response.item)
      })
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    get_data();
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Discounts.Edit' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Discounts' })}</li>
          </ul>
        </div>
        <div>
          <Link to={`/Users/Edit-User-Discounts/${data.user_id}`} type='button' className='btn btn-primary' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </Link>
        </div>
      </div>
      <div id="kt_app_content_container" className="app-container container-xxl">
        <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>

              <div className="card-body pt-0">
                <div className="row flex-between">
                  <div className="col-6 my-5">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.Product' })}  </label>
                    <ProductsAutoComplete value={user_value} setValue={setUSER_Value} />
                  </div>
                  <div className="col-6  my-5 ">
                    <label className="required form-label"> {intl.formatMessage({ id: 'Form.DiscountType' })}  </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name='type'
                        onChange={handleChange}
                        value={data.type}
                      >
                        {types.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {data.type === 'fixed' ?
                    <>
                      <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Percentage' })}</label>
                        <input type="number" name="percentage" className="form-control py-5" value={data.percentage} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Percentage' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.percentage &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.percentage}</span>
                            </div>
                          </div>
                        }
                      </div>
                      <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.UpTo' })}</label>
                        <input type="number" name="up_to" className="form-control py-5" value={data.up_to} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.UpTo' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.up_to &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.up_to}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </>
                    :
                    <div className=" my-5 fv-row col-6 fv-plugins-icon-container">
                      <label className="required form-label"> {intl.formatMessage({ id: 'Form.Discount' })}</label>
                      <input type="number" name="discount" className="form-control py-5" value={data.discount} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Discount' })} />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      {errors.discount &&
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.discount}</span>
                          </div>
                        </div>
                      }
                    </div>
                  }

                </div>
              </div>
            </div>

            <button type="submit" className="btn col-2 btn-primary">
              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div >

    </motion.nav >
  )
}
