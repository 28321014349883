import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import { FormControl, MenuItem, Select } from '@mui/material'

export const ExportProduct = ({ handleClose, show, get_data }) => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('all')
    const [errors, setErrors] = useState([]);
    const [items, setItems] = useState([])

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        try {
            event.preventDefault()
            const formData = new FormData()
            formData.append('type', type);
            const response = await axiosInstance.post(`/export-items`, formData, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setLoading(false);
            setErrors([]);
        } catch (e) {
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }
    const getData = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get('/admin-cats')
            console.log('Fetched Data:', data)
            setItems(data)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Modal show={show} onHide={handleClose} size="lg" >
            <Modal.Header closeButton>
                {intl.formatMessage({ id: 'Table.Export' })}
            </Modal.Header>
            <form onSubmit={handleEdit_Add_submit}>
                <Modal.Body>
                    <div className="col-12">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Select_cateory' })}  </label>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select"
                                onChange={(e) => setType(e.target.value)}
                                value={type}
                            >
                                <MenuItem value='all'>
                                    All
                                </MenuItem>
                                {items.map((row, key) => (
                                    <MenuItem key={key} value={row.id}>
                                        {row.name_en}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => handleClose(false)}>
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary">
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Create' })} </span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
