import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { KTSVG } from '../../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';

export const BinanceEditProducts = () => {
    const intl = useIntl()
    const [user_value, setUSER_Value] = useState(null);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams()

    const types = [
        { id: 'stock', name: 'Stock' },
        { id: 'api', name: 'Api' },
    ];

    const [data, setData] = useState({
        img: null,
        name_en: null,
        name_ar: null,
        des_en: null,
        des_ar: null,
        price: 0,
        cost: 0,
        sale_priority: 'api',
        min_stock: 0,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const changeHandler_iamge = (event) => {
        const name = event.target.name;
        setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
    };

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append('_method', 'PATCH');
        try {
            await axiosInstance.post(`/admin-binance-products/${id}`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                navigate(-1)
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }
    const get_data = async () => {
        await axiosInstance.get(`/admin-binance-products/${id}`).then(function (res) {
            const response = res.data;
            setData({
                img: null,
                img_name: response.img,
                name_en: response.name_en,
                name_ar: response.name_ar,
                des_en: response.des_ar,
                des_ar: response.des_ar,
                price: response.price,
                cost: response.cost,
                sale_priority: response.sale_priority,
                min_stock: response.min_stock,
            });
        })
    }


    useEffect(() => {
        get_data()
    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Products.Edit' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Binance' })}</li>
                    </ul>
                </div>
                <div>
                    <button onClick={() => navigate(-1)} type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </button>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.ImageContent' })} </h2>
                                </div>
                            </div>

                            <div className="card-body text-center pt-0">
                                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                                    <div className="file" >
                                        <form onClick={() => document.querySelector(".input-field2").click()} >
                                            <input type="file" accept='image/*' className='input-field2' name='img' hidden
                                                onChange={changeHandler_iamge}
                                            />
                                            {data.img === null && data.img_name ?
                                                <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width={150} height={150} alt='' />
                                                :
                                                data.img ?
                                                    <img src={URL.createObjectURL(data.img)} width={150} height={150} alt='' />
                                                    :
                                                    <>
                                                        <MdCloudUpload color='#1475cf' size={60} />
                                                        <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                                                    </>
                                            }
                                        </form>
                                        {errors.img &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.img}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                                </div>
                            </div>

                            <div className="card-body pt-0">

                                <div className="row flex-between">
                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.sale_priority' })}  </label>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="sale_priority"
                                                onChange={handleChange}
                                                value={data.sale_priority}
                                            >
                                                {types.map((row, key) => (
                                                    <MenuItem key={key} value={row.id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.mini_stock' })}</label>
                                        <input type="text" name="min_stock" className="form-control py-4" value={data.min_stock} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.mini_stock' })} />
                                        <div className="fv-plugins-message-container  invalid-feedback" />
                                        {errors.min_stock &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.min_stock}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameEN' })}  </label>
                                        <input type="text" name="name_en" className="form-control mb-2" value={data.name_en} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameEN' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.name_en &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.name_en}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.NameAR' })}</label>
                                        <input type="text" name="name_ar" className="form-control mb-2" value={data.name_ar} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.NameAR' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.name_ar &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.name_ar}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Cost' })}</label>
                                        <input type="number" name="cost" className="form-control mb-2" value={data.cost} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Cost' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.cost &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.cost}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-3 col-6 fv-row fv-plugins-icon-container">
                                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Price' })}</label>
                                        <input type="number" name="price" className="form-control mb-2" value={data.price} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Price' })} />
                                        <div className="fv-plugins-message-container invalid-feedback" />
                                        {errors.price &&
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{errors.price}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>



                                </div>

                            </div>
                        </div>

                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.ItemDetails' })}</h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-6 col-sm-12">
                                        <div>
                                            <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionEN' })}</label>
                                            <textarea name="des_en" className='form-control mb-2'
                                                maxLength="250"
                                                onChange={handleChange}
                                                cols="30" rows="5" value={data.des_en} >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 col-lg-6 col-sm-12">
                                        <div>
                                            <label className="form-label">{intl.formatMessage({ id: 'Form.DiscretionAR' })}</label>
                                            <textarea name="des_ar" className='form-control mb-2'
                                                maxLength="250"
                                                onChange={handleChange}
                                                cols="30" rows="5" value={data.des_ar}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </motion.nav>
    )
}
