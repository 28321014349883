import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { MdCloudUpload } from 'react-icons/md';
import { useIntl } from 'react-intl';
import useAuthContext from '../../Auth/AuthContext';
import axiosInstance from '../../api/axios';

const Profile = () => {
    const { user, getUser } = useAuthContext()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const intl = useIntl()
    const [user_value, setUSER_Value] = useState(null);
    const [data, setData] = useState({
        id: user.id,
        name: user.name,
        img: null,
        img_name: user.img,
        password: null,
        confirm_password: null,
        email: user.email,
    })

    const [fileName, setFileName] = useState("No selected file")
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const changeHandler_iamge = (event) => {
        const name = event.target.name;
        setData((prevData) => ({ ...prevData, [name]: event.target.files[0] }));
        setFileName(event.target.files[0].name)
    };

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        formData.append('_method', 'PATCH');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        try {
            await axiosInstance.post(`/user/${user.id}`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                getUser()
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }

    return (
        <motion.nav
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
        <div id="kt_app_content_container" className="app-container container-xxl">
          <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
  
            <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Form.MainImage' })} </h2>
                  </div>
                </div>
  
                <div className="card-body text-center pt-0">
                  <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                    <div className="file" >
                      <form onClick={() => document.querySelector(".input-field").click()} >
                        <input type="file" accept='image/*' className='input-field' name='img' hidden
                          onChange={changeHandler_iamge}
                        />
  
                        {data.img === null && data.img_name ?
                          <img src={process.env.REACT_APP_IMAGE_PATH + data.img_name} width={150} height={150} alt={fileName} />
                          :
                          data.img ?
                            <img src={URL.createObjectURL(data.img)} width={150} height={150} alt={fileName} />
                            :
                            <>
                              <MdCloudUpload color='#1475cf' size={60} />
                              <p> {intl.formatMessage({ id: 'Form.BrowseFilestoupload' })} </p>
                            </>
                        }
  
                      </form>
                    </div>
                  </div>
                  {errors.show_img &&
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.show_img}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
  
            </div>
  
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
  
  
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                  </div>
                </div>
  
                <div className="card-body pt-0">
  
                  <div className="row flex-between mb-3">
             
                    {/* name */}
                    <div className=" col-6 mb-3">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Name' })}</label>
                        <input type="text" name="name" className="form-control mb-2" value={data.name} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Name' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.name &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.name}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
  
                    {/* email */}
                    <div className=" col-6 mb-3">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Email' })} </label>
                        <input type="text" name="email" className="form-control mb-2" value={data.email} onChange={handleChange} placeholder= {intl.formatMessage({ id: 'Form.Email' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.email &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.email}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
  
                    {/* Password */}
                    <div className=" col-6 mb-3">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label"> {intl.formatMessage({ id: 'Form.Password' })} </label>
                        <input type="text" name="password" className="form-control mb-2" value={data.password} onChange={handleChange} placeholder= {intl.formatMessage({ id: 'Form.Password' })} />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.password &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.password}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className=" col-6 mb-3">
                      <div className="mb-3 fv-row fv-plugins-icon-container">
                        <label className="required form-label">{intl.formatMessage({ id: 'Form.ConfirmPassword' })} </label>
                        <input type="text" name="confirm_password" className="form-control mb-2" value={data.confirm_password} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.ConfirmPassword' })}  />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        {errors.confirm_password &&
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.confirm_password}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
  
  
  
  
  
                  </div>
  
  
  
                </div>
              </div>
  
              <div className="d-flex justify-content-end ">
                <button type="submit" className="btn btn-primary">
                  {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </motion.nav>
    )
}

export default Profile