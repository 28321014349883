import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../api/axios'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import Swal from 'sweetalert2'

export const MerchantProducts = ({ user }) => {
    const [data, setData] = useState([])
    const intl = useIntl()

    const get_data = async () => {
        await axiosInstance.get(`/admin-merchants`, {
            params: { user_id: user.id },
        }).then(function (res) {
            const response = res.data;
            setData(response);
        })
    }
    const CustomDiv = () => (
        <div className="row flex-end w-100">
            <div className="col-auto">
                <Link to={`/Add-Merchant-Products/${user.id}`} type='button' className='btn btn-primary' >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    {intl.formatMessage({ id: 'Products.Create' })}
                </Link>
            </div>
        </div>
    );

    const TruncateString = ({ text }) => {
        const truncatedText = text ? text.slice(0, 50) : '';
        return truncatedText;
    };

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const { data } = await axiosInstance.delete(`/admin-merchants/${id}`);
                    if (!data || data.length === 0) {
                        throw new Error(JSON.stringify(data));
                    }
                    get_data()
                } catch (error) {
                    Swal.showValidationMessage(`
                  Request failed: ${error}
              `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Done!",
                    icon: "success"
                });
            }
        });

    }

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'item.id',
            Cell: row => (
                <div className="w-100 flex-center ">
                    # {row.row.original.item.id}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.File' }), accessor: 'File',
            Cell: row => (
                <div className="symbol symbol-50px ">
                    <img src={row.row.original.item.img ? process.env.REACT_APP_IMAGE_PATH + row.row.original.item.img : toAbsoluteUrl("media/avatars/300-6.jpg")} alt="" />
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'item.name_en',
            Cell: row => (
                <div className="w-300 flex-center fs-12 f-bold">
                    <div>
                        <TruncateString text={row.row.original.item.name_en} />
                    </div>
                    <div>
                        <TruncateString text={row.row.original.item.name_ar} />
                    </div>
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.Type' }), accessor: 'type',
            Cell: row => (
                <div className="w-100 flex-center ">
                     {row.row.original.type}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Percentage' }), accessor: 'percentage',
            Cell: row => (
                <div className="w-100 flex-center ">
                     {row.row.original.type === 'fixed' ?  row.row.original.percentage +' %' : '---'}

                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.UpTo' }), accessor: 'up_to',
            Cell: row => (
                <div className="w-100 flex-center ">
                     {row.row.original.type === 'fixed' ?  row.row.original.up_to : '---'}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Discount' }), accessor: 'discount',
            Cell: row => (
                <div className="w-100 flex-center ">
                     {row.row.original.type !== 'fixed' ?  row.row.original.discount : '---'}
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                    <div className="d-flex flex-center my-2">
                        <Link to={`/Edit-Merchant-Products/${row.row.original.id}`} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </Link>
                        <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2  w-100 btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
                    </div>
                   
            ),
        },
    ];

    useEffect(() => {
        get_data();
    }, [])

    return (
        <div className="card px-3">
                <ReactTable columns={columns} data={data} customComponent={<CustomDiv />} />

        </div>
    )
}
