import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { Skeleton } from '@mui/material';
import CryptoJS from 'crypto-js';

const SupplerCategories = () => {
    const { id } = useParams();
    const [Items, setItems] = useState(null)
    const [Item, setItem] = useState(null)
    const intl = useIntl()
    const [loading, setLoading] = useState(false)


    const getdata = async () => {
        setLoading(true)
        await axiosInstance.get(`/admin-suppliers/${id}`).then(function (res) {
            const response = res.data;
            setItem(response)
            get_items(response.value)
        });
    };

    const get_items = async (value) => {
        if (value === 'bitaqaty') {
            const appSecret = process.env.REACT_APP_BITAQATY_APP_SCREET.toString();
            const { data } = await axiosInstance.get('/Bitaqaty/get-Merchant-id', {
                headers: {
                    'token': appSecret,
                }
            })
            setItems(data);
            setLoading(false)
        }
    };

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
            Cell: row => (
                <div className=" w-100 flex-center">
                    #  {row.row.original.id}
                </div>
            )
        },

        {
            Header: intl.formatMessage({ id: 'Table.NameEN' }),
            accessor: 'name',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.name}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.NameAR' }),
            accessor: 'name_ar',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.name_ar}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center">
                    <Link to={`/Suppliers/get-Products/${Item.value}/${row.row.original.id}`} target='_blank' className='btn me-2  btn-light-primary w-100'> {intl.formatMessage({ id: 'Menu.Products' })} </Link>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getdata();
    }, []);

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{Item ? Item.name : ''}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Suppliers' })}</li>
                    </ul>
                </div>
            </div>
            {Items !== null && !loading ?
                <ReactTable columns={columns} data={Items} customComponent={<></>} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>
    )
}

export default SupplerCategories