import  {  useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import useAuthContext from '../../Auth/AuthContext';
//google login 
import logo from '../../../_metronic/assets/Wrkbee/User/logo.png'
import axios from '../../api/axios';
import { Loading } from '../LoadingPage/Loading';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Swal from 'sweetalert2';

const EmailVerification = () => {
const {user , logout , getUser} = useAuthContext()
const [email , setEmail] = useState('');
const [status , setStatus] = useState('');
const [loading, setLoading] = useState(false)
const [loading2, setLoading2] = useState(false)
const [loading3, setLoading3] = useState(false)
const [loading4, setLoading4] = useState(false)

const [User_Code, setUser_Code] = useState('')
const [errors , setErrors] = useState([]);

///email/verification-notification
  const handleEdit_verify_submit = async(event)=>{
    setLoading2(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('code', User_Code)
    try{
      await  axios.post(`/api/user-verify`,formData).then(response =>{
        if(response.data.status === 500)
        {
          setLoading2(false)
          Swal.fire({
            title: "Sorry",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "OK",
          });

        }
        else{
          setErrors([])
          setLoading2(false)
          getUser()
        }
      })
    }catch(e){
            console.log(e.response.data.errors);
            if(e.response.status == 422)
            {
                setLoading2(false)
                setErrors(e.response.data.errors)
            }
        }
  }

  const sendMail = async()=>{
    setLoading3(true)
    const response = await axios.post('/api/user-send-verification-code');
    setStatus(response.data.status)
		setLoading3(false)
  }
  useEffect(()=>{
    sendMail()
  },[])
  return (
	<>
	{loading &&  <Loading/>}
	
    <div className=" d-flex flex-center" style={{marginTop:'15vh'}} >
      <div className='col-lg-6 col-sm-10'  style={{minHeight:'100vh',justifyContent:'center'}}>
        <div className="d-flex justify-content-center mt-5">
           <img src={logo}  style={{width:'40%'}} alt="" /> 
        </div>
        <div className=" ps-5 pe-5 py-3  card">
        {status && <div className='btn btn-success text-center  col-12 mb-5 mt-3'> <h6 className='text-white mt-2'>verification-code-sent</h6> </div>}

            <p className='text-center mt-2'>
              Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email , 
              we will gladly send you another
            </p>
         
            <div className="row flex-center mt-0 pt-0 mb-5 ">
                <div className="d-flex mt-2 col-11 flex-between ">
                    <div className='col-8 p-3'>
                    
                        <FormControl fullWidth >
                          <TextField 
                            value={User_Code}
                            size="small"
                            onChange={(e)=>setUser_Code(e.target.value)}
                            type='number'
                            id="outlined-start-adornment"
                            placeholder='verification code'
                          />
                          </FormControl>
                         
                        
                    </div>
                    <button className='login col-lg-4 col-sm-9' onClick={handleEdit_verify_submit}>	
                      {!loading2 && <span className='indicator-label'> Submit</span>}
                        {loading2 && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )
                        }
                    </button>
                </div>
                <div className="row col-11 mb-3 flex-center">
                    {errors.code &&
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert'>{errors.code}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="row col-8 flex-center">
                  <div className='  fv-row col-lg-6 '>
                    <button className='login w-100 ' onClick={sendMail}>	
                        {!loading3 && <span className='indicator-label'> Resend Verification code</span>}
                          {loading3 && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                          )
                          }
                    </button>
                  </div>
                  <div className='  fv-row col-lg-6 '>
                    <a className='btn btn-outline-danger  w-100 ' onClick={(e)=>[logout() , setLoading4(true)]} >	
                        {!loading4 && <span className='indicator-label'> Sing out</span>}
                          {loading4 && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                          )
                          }
                      </a>
                  </div>
                   
            </div>
            </div>
     
        
        </div>
      </div>
  </div>
	</>
  )
}

export default EmailVerification