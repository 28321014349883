import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MerchantProducts } from './MerchantProducts';
export const MerchantProfile = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [value, setValue] = useState(0);

    const get_data = async () => {
        await axiosInstance.get(`/admin-users/${id}`).then(function (res) {
            const response = res.data;
            setUser(response);
        })
    }

    useEffect(() => {
        get_data()
    }, [])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'User.Edit' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Users' })}</li>
                    </ul>
                </div>
                <div>
                    <Link to='/Users/Users' type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </Link>
                </div>
            </div>
            {user ?
                <div>
                    <div className='card mb-3 mb-xl-5'>
                        <div className='card-body pt-9 pb-0'>
                            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                                <div className='me-7 mb-4'>
                                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                        <img src={process.env.REACT_APP_IMAGE_PATH + user.img} alt='user profile' />
                                        <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                                    </div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                        <div className='d-flex flex-column'>
                                            <span className='text-gray-800 text-hover-primary fs-8 fw-bolder  '>
                                                {intl.formatMessage({ id: 'Table.ID' })} : {user.id}
                                            </span>
                                            <div className='d-flex align-items-center mb-2'>

                                                <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                                    {user.name}
                                                </span>
                                                {user.email_verified_at &&
                                                    <span>
                                                        <KTSVG path='/media/icons/duotune/general/gen026.svg' className='svg-icon-1 svg-icon-primary' />
                                                    </span>
                                                }
                                            </div>

                                            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                                <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-3'  >
                                                    <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-4 me-1' />
                                                    {user.type}
                                                </span>
                                                {user.email &&
                                                    <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-3'  >
                                                        <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-4 me-1' />
                                                        {user.email}
                                                    </span>
                                                }
                                                {user.phone &&
                                                    <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                                                        <FaPhoneAlt className='svg-icon-4 me-1' />
                                                        {user.phone}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-wrap flex-stack'>
                                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                                            <div className='d-flex flex-wrap'>
                                                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                    <div className='d-flex align-items-center flex-center me-3'>
                                                        <div className='fs-2 fw-bolder'>{user.wallet}</div>
                                                    </div>
                                                    <div className='fw-bold fs-6 text-gray-400 text-center ' >{intl.formatMessage({ id: 'Table.Wallet' })} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex overflow-auto '>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} aria-label="basic tabs example">
                                            <Tab label={intl.formatMessage({ id: 'Menu.Products' })} onClick={(e) => setValue(0)} />
                                        </Tabs>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    {/* <div> 
                        {value === 0 && <ProfileTab user={user} change_Value={setValue}  Times={Times}  />}
                        {value === 1 && <ItemsTab user={user} Packs ={Packs} />}
                        {value === 2 && <SettingTab user={user} get_user={get_user}/>}
                        {value === 3 && <UserTarget id={user.id} /> }
                        {value === 4 && <UserEventReports   id={id} /> }
                        {value === 5 && <UserMoment  id={id} /> }
                        {value === 6 && <UserReels  id={id} /> }
                        {value === 7 && <UserGroupChat  id={id} /> }
                        {value === 8 && <UserReports  id={id} /> }
                        {value === 9 && <UserBans  id={id} /> }
                    </div> */}
                    <div> 
                        {value === 0 && <MerchantProducts user={user}   />}
                    
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '40vh', marginTop: '5vh' }} />
                    </div>
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '60vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>
    )
}
