import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import axiosInstance from '../../../api/axios';

export const ImportProducts = ({ handleClose, show, get_data }) => {
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const intl = useIntl()

    const changeHandler_iamge = (event) => {
        setFile(event.target.files[0]);
    };

    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        formData.append('file', file)
        try {
            await axiosInstance.post(`/import-items`, formData).then(response => {
                get_data()
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });

                handleClose(false)
                setFile(null)
            })
        } catch (e) {
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }

    }

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'Table.Import' })}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleEdit_Add_submit}>

                <Modal.Body>
                    <div className="d-flex mt-5">
                        <div className='fv-row col-12' data-kt-password-meter='true'>
                            <div className='mb-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'Table.ImportFormat' })}</label>
                                <Table bordered >
                                    <thead>
                                        <tr>
                                            <th style={{ border: ' black solid 0.5px', width: 'auto' }}> {intl.formatMessage({ id: 'Table.ID' })} </th>
                                            <th style={{ border: ' black solid 0.5px', width: 'auto' }}> {intl.formatMessage({ id: 'Table.DescriptionEN' })} </th>
                                            <th style={{ border: ' black solid 0.5px', width: 'auto' }}> {intl.formatMessage({ id: 'Table.DescriptionAR' })} </th>
                                            <th style={{ border: ' black solid 0.5px', width: 'auto' }}> {intl.formatMessage({ id: 'Table.Price' })} </th>
                                        </tr>
                                    </thead>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-5">
                        <div className='fv-row col-12' data-kt-password-meter='true'>
                            <div className='mb-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>File</label>
                                <div className='position-relative mb-3'>
                                    <Form.Control type="file" onChange={changeHandler_iamge} />
                                </div>
                                {errors.file &&
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{errors.file}</span>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button   Button variant="secondary" onClick={(e) => handleClose(false)}>
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary">
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Table.Import' })} </span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
