import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
export const EditProductStock = ({ handleClose, show, get_data, id }) => {
  const intl = useIntl()
  const [user_value, setUSER_Value] = useState(null);
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    pin: null,
    code: null,
  });

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('_method', 'PATCH');
     try {
      await axiosInstance.post(`/admin-stocks/${id}`, formData).then(response => {
        setLoading(false)
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        get_data()
        handleClose(false)
        setErrors([])
        setUSER_Value(null)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
      else if (e.response.status === 423) {
        setLoading(false)
        Swal.fire({
          title: "Error",
          icon: "error",
          text: e.response.data.message,
        });
      }
    }
  }
  console.log(id);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };


  const get_stock = async () => {
    await axiosInstance.get(`/admin-stocks/${id}`).then(function (res) {
      const response = res.data;
      setData({
        code: response.code,
        pin: response.pin,
      });
    })
  }

  useEffect(() => {
    if (show) {
      setData({
        code: '',
        pin: '',
      });

      setErrors([])
      get_stock()
    }
  }, [show])

  return (
    <Modal show={show} onHide={handleClose} size="lg" >
      <Modal.Header closeButton>
        {intl.formatMessage({ id: 'Stock.Edit' })}
      </Modal.Header>
      <form onSubmit={handleEdit_Add_submit}>
        <Modal.Body>
          <div className="row ">
            <div className="mb-3 fv-row fv-plugins-icon-container">
              <label className="required form-label"> {intl.formatMessage({ id: 'Form.Code' })}  </label>
              <input type="text" name="pin" className="form-control mb-2" value={data.pin} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Code' })} />
              <div className="fv-plugins-message-container invalid-feedback" />
              {errors.pin &&
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.pin}</span>
                  </div>
                </div>
              }
            </div>

            <div className="mb-3 fv-row fv-plugins-icon-container">
              <label className="required form-label"> {intl.formatMessage({ id: 'Form.Refnumber' })}</label>
              <input type="text" name="code" className="form-control mb-2" value={data.code} onChange={handleChange} placeholder={intl.formatMessage({ id: 'Form.Refnumber' })} />
              <div className="fv-plugins-message-container invalid-feedback" />
              {errors.code &&
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.code}</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => handleClose(false)}>
            Close
          </Button>
          <button type="submit" className="btn btn-primary">
            {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
