import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import ProductsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/ProductsAutoComplete'
export const AddNewArrivals =  ({handleClose , show , get_data}) => {
    const intl = useIntl()
    const [user_value, setUSER_Value] = useState(null);
    const [loading , setLoading ] = useState(false)
    const [errors , setErrors] = useState([]);

    const handleEdit_Add_submit = async(event)=>{
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('product_id[]', user_value.id);
        try{
          await  axiosInstance.post(`/admin-newArrival`,formData).then(response =>{
                setLoading(false)
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                get_data()
                handleClose(false)
                setErrors([])
                setUSER_Value(null)
            })
        }catch(e){
          console.log(e.response.data.errors);
            if(e.response.status === 422)
            {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
            else if(e.response.status === 423)
            {
                setLoading(false)
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: e.response.data.message,
                });
            }
        }
    }

    useEffect(()=>{
        setUSER_Value(null)
        setErrors([])
    },[])

  return (
       <Modal  show={show} onHide={handleClose}  size="lg" >
        <Modal.Header closeButton>
          {intl.formatMessage({ id: 'Form.Add' })}
        </Modal.Header>
        <form  onSubmit={handleEdit_Add_submit}>
            <Modal.Body>
            <div className="mb-3 fv-row fv-plugins-icon-container">
                <label className="required form-label"> {intl.formatMessage({id: 'Form.Product'})}  </label>
                <ProductsAutoComplete value={user_value} setValue={setUSER_Value}  />
                <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            </Modal.Body>
            <Modal.Footer>
                    <Button variant="secondary" onClick={(e)=>handleClose(false)}>
                            Close
                    </Button>
                    <button type="submit"  className="btn btn-primary">
                    {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Form.Create'})} </span>}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
            </Modal.Footer>
        </form>
    </Modal>
  )
}
