import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import axiosInstance from '../../../api/axios';
import { Button, Modal } from 'react-bootstrap';
import { FormControl, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

export const ExportOrders = ({ handleClose, show }) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const order_status = [
        { id: 'failed', name_en: 'Failed' },
        { id: 'pending', name_en: 'Pending' },
        { id: 'completed', name_en: 'Completed' },
    ];

    const admin_status = [
        { id: 'refused', name_en: 'Refused' },
        { id: 'pending', name_en: 'Pending' },
        { id: 'approved', name_en: 'Approved' },
    ];

    const [data, setData] = useState({
        order_status: 'completed',
        admin_approved: 'approved',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEdit_Add_submit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('enctype', 'multipart/form-data');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        try {
            const response = await axiosInstance.post('/admin-orders', formData, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'orders.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setLoading(false);
            setErrors([]);
        } catch (e) {
            if (e.response && e.response.status === 422) {
                setErrors(e.response.data.errors);
            }
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                {intl.formatMessage({ id: 'Table.Export' })}
            </Modal.Header>
            <form onSubmit={handleEdit_Add_submit}>
                <Modal.Body>
                    <div className="row flex-between">
                        <div className="col-lg-6 col-md-6 my-5">
                            <h6>{intl.formatMessage({ id: 'Form.StartDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    value={data.start_date}
                                    onChange={([start_date]) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            start_date: moment(start_date).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 my-5">
                            <h6>{intl.formatMessage({ id: 'Form.EndDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    options={{ minDate: data.start_date }}
                                    value={data.end_date}
                                    onChange={([end_date]) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            end_date: moment(end_date).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>

                        <div className="col-6 my-5">
                            <label className="required form-label">{intl.formatMessage({ id: 'Table.Status' })}</label>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select"
                                    onChange={handleChange}
                                    name='order_status'
                                    value={data.order_status}
                                >
                                    {order_status.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                            {row.name_en}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-6 my-5">
                            <label className="required form-label">{intl.formatMessage({ id: 'Table.AdminApproved' })}</label>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select"
                                    onChange={handleChange}
                                    name='admin_approved'
                                    value={data.admin_approved}
                                >
                                    {admin_status.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                            {row.name_en}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => handleClose(false)}>
                        Close
                    </Button>
                    <Button type="submit" className="btn btn-primary" disabled={loading}>
                        {!loading && intl.formatMessage({ id: 'Form.Create' })}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'Form.Pleasewait' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};
