import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import ReactTableWithPagention from '../../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import { Images } from '../../../../../_metronic/partials/widgets/React_Table/Images'

const LevelRequestsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState(null);


  const getData = async () => {
    const { data } = await axiosInstance.get(`/admin-levels-requests?page=${currentPage}`, {
      params: { 'name': inputValue },
    })
    setItems(data.data)
    setTotalPages(data.meta.last_page);
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await axiosInstance.delete(`/admin-levels-requests/${id}`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.User' }),
      accessor: row => `${row.user?.name} ${row.user?.id}`,
      Cell: row => (
        row.row.original.user &&
        <div className='d-flex flex-center align-items-center ps-3'>
          <Images img={row.row.original.user.img} name={row.row.original.user.name} />
          <div className=''>
            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
              {row.row.original.user.name}
            </div>
            <span className='text-muted d-block fw-semibold text-start'>#{row.row.original.user.id}</span>
          </div>
        </div>
      ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter(row => {
          const user = row.values[id]; // id will be 'user' which is the accessor
          return (
            user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
          );
        });
      }
    },
    {
      Header: intl.formatMessage({ id: 'Table.Level' }),
      accessor: row => `${row.level?.name} ${row.level?.id}`,
      Cell: row => (
        row.row.original.level &&
        <div className='d-flex align-items-center ps-3 flex-center'>
          <Images img={row.row.original.level.img} name={row.row.original.level.name} />
          <div className=''>
            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
              {row.row.original.level.name}
            </div>
            <span className='text-muted d-block fw-semibold text-start'>#{row.row.original.level.id}</span>
          </div>
        </div>
      ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter(row => {
          const level = row.values[id]; // id will be 'level' which is the accessor
          return (
            level.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
          );
        });
      }
    },
    {
      Header: intl.formatMessage({ id: 'Table.Status' }),
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.status === -1 &&
            <span className="badge badge-light-danger">Refused </span>
          }
          {row.original.status === 0 &&
            <span className="badge badge-light-warning">Pending</span>
          }
          {row.original.status === 1 &&
            <span className="badge badge-light-success">Approved </span>
          }
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Date' }),
      accessor: 'created_at',
      Cell: ({ row }) => (
        <div className="w-100 flex-center">
          {row.original.created_at}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: ({ row }) => (
        <div className="d-flex flex-center">
          <Link to={`/Levels/Edit-Levels-Requests/${row.original.id}`} className='btn me-2 btn-light-primary'>
            {intl.formatMessage({ id: 'Table.View' })}
          </Link>
          <button onClick={() => deleteItem(row.original.id)} className='btn me-2 btn-light-danger'>
            {intl.formatMessage({ id: 'Table.Delete' })}
          </button>
        </div>
      )
    }
  ];

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  useEffect(() => {
    getData()
  }, [inputValue, currentPage])

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
            {intl.formatMessage({ id: 'Menu.LevelsRequests' })}
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Levels' })}
            </li>
          </ul>
        </div>
      </div>

      {!loading ?
        <ReactTableWithPagention
          columns={columns}
          data={items}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          customComponent={<></>} />
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
    </motion.nav>
  )
}

export default LevelRequestsHome
