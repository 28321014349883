import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../../_metronic/helpers';
import { MdCloudUpload } from 'react-icons/md';
import { Images } from '../../../../../_metronic/partials/widgets/React_Table/Images';
import { Skeleton } from '@mui/material';

export const UpdateLevelRequests = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);


  const change_status = async (status) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('status', status);
    formData.append('_method', 'PATCH');
    try {
      await axiosInstance.post(`/admin-levels-requests/${id}`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors);
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    await axiosInstance.get(`/admin-levels-requests/${id}`).then(function (res) {
      const response = res.data.data;
      setData(response);
    })
  }

  const open_window = (path) => {
    window.open(process.env.REACT_APP_IMAGE_PATH + path, '_blank');
  };
  useEffect(() => {
    get_data()
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
      <div className=" mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.LevelsRequests' })}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Levels' })}</li>
          </ul>
        </div>
        <div>
          <button onClick={() => navigate(-1)} type='button' className='btn btn-primary me-2' >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </button>
          <button onClick={() => change_status(1)} type='button' className='btn btn-success me-2' >
            {intl.formatMessage({ id: 'Form.Approved' })}
          </button>
          <button onClick={() => change_status(-1)} type='button' className='btn btn-danger me-2' >
            {intl.formatMessage({ id: 'Form.Refused' })}
          </button>
        </div>
      </div>
      {data ?
        <div id="kt_app_content_container" className="app-container container-xxl">
          <div  id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
            <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Table.ProfileImage' })} </h2>
                  </div>
                </div>

                <div className="card-body text-center pt-0">
                  <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                    <div className="file" >
                      <img src={process.env.REACT_APP_IMAGE_PATH + data.user.img} width={150} height={150} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
              <div className="row flex-between">
                <div className="col-6">
                  <div className="card card-flush py-4">
                    <div className="card-header">
                      <div className="card-title">
                        <h2>{intl.formatMessage({ id: 'Table.UserInof' })} </h2>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div id='kt_account_connected_accounts1' className='collapse show'>
                        <div className='py-2'>
                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Name' })}  </label>

                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark'>{data.user.name}</span>
                            </div>
                          </div>

                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Email' })} </label>

                            <div className='col-lg-8 fv-row'>
                              <span className='fw-bolder fs-6 text-dark'>{data.user.email ?? '---'}</span>
                            </div>
                          </div>

                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              Contact Phone
                              <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                              ></i>
                            </label>

                            <div className='col-lg-8 d-flex align-items-center'>
                              <span className='fw-bolder fs-6 me-2'>{data.user.phone ?? '---'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card card-flush py-4">
                    <div className="card-header">
                      <div className="card-title">
                        <h2>{intl.formatMessage({ id: 'Table.LevelInof' })} </h2>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div id='kt_account_connected_accounts1' className='collapse show'>
                        <div className='py-2'>
                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Name' })}  </label>

                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark'>{data.level.name}</span>
                            </div>
                          </div>

                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.DailyLimit' })} </label>

                            <div className='col-lg-8 fv-row'>
                              <span className='fw-bolder fs-6 text-dark'>{data.level.daily_limit ?? '---'}</span>
                            </div>
                          </div>
                          <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.MonthlyLimit' })} </label>

                            <div className='col-lg-8 fv-row'>
                              <span className='fw-bolder fs-6 text-dark'>{data.level.monthly_limit ?? '---'}</span>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{intl.formatMessage({ id: 'Table.Requirements' })}</h2>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="mb-3 fv-row fv-plugins-icon-container mt-3 ">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          <tr>
                            <th>{intl.formatMessage({ id: 'Form.DiscretionEN' })}</th>
                            <th>{intl.formatMessage({ id: 'Form.DiscretionAR' })}</th>
                            <th>{intl.formatMessage({ id: 'Form.File' })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.level.requirements.map((row, key) => (
                            <tr key={key}>
                              <td style={{ width: '400px' }}>
                                {row.des_en}
                              </td>
                              <td style={{ width: '400px' }}>
                                {row.des_ar}
                              </td>
                              <td style={{ width: '400px' }}>
                                <button onClick={() => open_window(data.level.files[key].file)} className='btn me-2 btn-light-primary'>
                                  {intl.formatMessage({ id: 'Table.View' })}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }

    </motion.nav>
  )
}
