import React from 'react'
import useAuthContext from '../../../Auth/AuthContext'

const AdminDashboard = () => {
  const {playSound} = useAuthContext()
  return (
    <div>
      <button onClick={playSound}>Dashboard</button>
    </div>
  )
}

export default AdminDashboard