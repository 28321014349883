import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { KTSVG } from '../../../../../_metronic/helpers';
import Swal from 'sweetalert2';

export const BitaqatyItems = () =>  {
  const { type, id } = useParams();
  const [Items, setItems] = useState(null);
  const [imported, setImported] = useState([]);
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cats, setCats] = useState([]);
  const [cat_id, setCat_id] = useState(null);
  const [errors, setErrors] = useState([]);

  const CustomDiv = () => (
    <div className="row flex-end w-100">
      <div className="col-auto">
        <button onClick={import_all} type='button' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({ id: 'Table.ImportAll' })}
        </button>
      </div>
    </div>
  );

  const CustomDiv2 = () => (
    <div className="row flex-end w-100">
      <div className="col-6">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{intl.formatMessage({ id: 'Form.Select_cateory' })} </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={intl.formatMessage({ id: 'Form.Select_cateory' })}
            onChange={(e) => setCat_id(e.target.value)}
            value={cat_id}
          >
            {cats.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );

  const getdata = async () => {
    setLoading(true);
    if (type === 'bitaqaty') {
      const appSecret = process.env.REACT_APP_BITAQATY_APP_SCREET.toString();
      const { data } = await axiosInstance.get(`/Bitaqaty/get-ProductsList/${id}`, {
        headers: {
          'token': appSecret,
        }
      });
      setItems(data.products);
      setLoading(false);
    }
  };

  const add_item = (item) => {
    const rowsInput = {
      type: type,
      id: item.productID,
      name_en: item.nameEn,
      name_ar: item.nameAr,
      available: item.available,
      price: item.recommendedRetailPriceAfterVat,
      vat_percentage: item.vatPercentage,
      country_id: ''
    };
    setImported((prevImported) => [...prevImported, rowsInput]);
  };

  const remove_item = (item) => {
    setImported((prevImported) => prevImported.filter(importedItem => importedItem.id !== item.productID));
  };

  const updateImportedItem = (id, key, value) => {
    setImported((prevImported) =>
      prevImported.map((item) =>
        item.id === id ? { ...item, [key]: value } : item
      )
    );
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    updateImportedItem(name, 'country_id', value);
  }
  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item);
    } else {
      remove_item(item);
    }
  };

  const isChecked = (id) => {
    return imported.some(importedItem => importedItem.id === id);
  };

  const import_all = () => {
    setImported([])
    const allItems = Items.map(item => ({
      type: type,
      id: item.productID,
      name_en: item.nameEn,
      name_ar: item.nameAr,
      available: item.available,
      price: item.recommendedRetailPriceAfterVat,
      vat_percentage: item.vatPercentage,
      country_id: ''
    }));
    setImported(allItems);
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'productID',
      Cell: row => (
        <div className="w-100 flex-center">
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => { handle_change(e, row.row.original) }}
              type='checkbox'
              checked={isChecked(row.row.original.productID)}
            />
            # {row.row.original.productID}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'nameEn',
      Cell: row => (
        <div className="w-100 flex-center fs-12">
          <div>
            {row.row.original.nameEn}
          </div>
          <div>
            {row.row.original.nameAr}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Available' }), accessor: 'available',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.available ?
            <span className="badge badge-light-success">{intl.formatMessage({ id: 'Table.Available' })}</span>
            :
            <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Table.notAvailable' })}</span>
          }
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.currency' }), accessor: 'currency',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.currency}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.recommended' }), accessor: 'recommended',
      Cell: row => (
        <div className="w-100 flex-center">
          <div className="row flex-between">
            <div className="col-8 text-start">
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.recommendedPriceAfterVat' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.recommendedPriceBeforeVat' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.recommendedPriceVatAmount' })}
              </div>
            </div>
            <div className="col-4 text-start">
              <div className='my-2 fs-12'>
                {': ' + row.row.original.recommendedRetailPriceAfterVat.toFixed(2)}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.recommendedRetailPriceBeforeVat.toFixed(2)}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.recommendedRetailPriceVatAmount.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Cost' }), accessor: 'Cost',
      Cell: row => (
        <div className="w-100 flex-center">
          <div className="row flex-between">
            <div className="col-8 text-start">
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.costPriceAfterVat' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.costPriceBeforeVat' })}
              </div>
              <div className='my-2 fs-12'>
                {intl.formatMessage({ id: 'Table.costPriceVatAmount' })}
              </div>
            </div>
            <div className="col-4 text-start">
              <div className='my-2 fs-12'>
                {': ' + row.row.original.costPriceAfterVat.toFixed(2)}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.costPriceBeforeVat.toFixed(2)}
              </div>
              <div className='my-2 fs-12'>
                {': ' + row.row.original.costPriceVatAmount.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )
    },
  ];

  const columns2 = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
      Cell: row => (
        <div className="w-100 flex-center">
          # {row.row.original.id}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Description' }), accessor: 'Description',
      Cell: row => (
        <div className="w-100 flex-center fs-12">
          <div>
            {row.row.original.name_en}
          </div>
          <div>
            {row.row.original.name_ar}
          </div>
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Available' }), accessor: 'available',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.available ?
            <span className="badge badge-light-success">{intl.formatMessage({ id: 'Table.Available' })}</span>
            :
            <span className="badge badge-light-danger">{intl.formatMessage({ id: 'Table.notAvailable' })}</span>
          }
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Price' }), accessor: 'price',
      Cell: row => (
        <div className="w-100 flex-center">
          {row.row.original.price.toFixed(2)}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'Table.Country' }), accessor: 'Country',
      Cell: row => (
        <div className="w-100 flex-center">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{intl.formatMessage({ id: 'Form.Types' })} </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={intl.formatMessage({ id: 'Form.Types' })}
              name={row.row.original.id}
              onChange={handleChange}
              value={row.row.original.country_id}
            >
              <MenuItem value=''>
                {intl.formatMessage({ id: 'Form.empty' })}
              </MenuItem>
              {countries.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )
    },
  ];

  const get_countries = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/admin-countries')
    setCountries(data)
  }

  const get_cats = async () => {
    setLoading(true)
    const { data } = await axiosInstance.get('/admin-cats')
    setCats(data)
  }
  const save_btn_handler = async (event) => {
    setLoading2(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('data', JSON.stringify(imported));
    formData.append('cat_id', cat_id);
    try {
      await axiosInstance.post(`/admin-items-import-from-cat`, formData).then(response => {
        Swal.fire({
          title: "Success",
          icon: "success",
          confirmButtonText: "OK",
        });
        setLoading2(false)
        setErrors([])
        setImported([])
        // navigate(`/WebDetails/Banners`)
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading2(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  useEffect(() => {
    getdata();
    get_countries();
    get_cats();
  }, []);

  useEffect(() => {
    if (cats.length > 0) {
      setCat_id(cats[0].id)
    }
  }, [cats]);


  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className="mb-5 mt-5 d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{Items ? Items[0].merchantNameEn : ''}</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              {intl.formatMessage({ id: 'Menu.Home' })}
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-400 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Suppliers' })}</li>
          </ul>
        </div>
      </div>
      {Items !== null && !loading ?
        <div>
          {imported.length > 0 &&
            <div className="row flex-between my-3">
              <div className="col-2">
                <button onClick={(e) => setStep(step - 1)} type='button' className='w-100 btn btn-secondary'>
                  <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                  {intl.formatMessage({ id: 'Table.Back' })}
                </button>
              </div>
              <div className="col-2">
                {step === 1 &&
                  <button onClick={(e) => setStep(step + 1)} type='button' className='w-100 btn btn-light-primary'>
                    {intl.formatMessage({ id: 'Table.Next' })}
                    <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2 me-0 ms-3' />
                  </button>
                }
                {step === 2 &&
                  <button onClick={save_btn_handler} type='button' className='w-100 btn btn-light-primary'>
                    {!loading2 && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Save' })} </span>}
                    {loading2 && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}

                  </button>
                }
              </div>
            </div>
          }
          {step === 1 &&
            <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
          }
          {step === 2 &&
            <ReactTable columns={columns2} data={imported} customComponent={<CustomDiv2 />} />
          }

        </div>
        :
        <div className="row">
          <div className="col-12">
            <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
          </div>
        </div>
      }
    </motion.nav>
  );
};

 