import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import { useIntl } from 'react-intl';
import moment from 'moment';
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../../_metronic/helpers';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable';
import exportToExcel from '../../../../../_metronic/partials/widgets/React_Table/exportToExcel';

export const BitaqatyReport = () => {
    const { type } = useParams();
    const [Items, setItems] = useState(null)
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const [dateState, setDateState] = useState({
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });

    const handleEdit_Add_submit = async (event) => {
        if (type === 'bitaqaty') {
            setLoading(true)
            const appSecret = process.env.REACT_APP_BITAQATY_APP_SCREET.toString();
            event.preventDefault()
            const formData = new FormData()
            formData.append('enctype', 'multipart/form-data');
            Object.entries(dateState).forEach(([key, value]) => {
                formData.append(key, value);
            });
            try {
                await axiosInstance.post(`/Bitaqaty/Reports`, formData, {
                    headers: {
                        'token': appSecret,
                    }
                }).then(response => {
                    setLoading(false)
                    // console.log(response.data);
                    setItems(response.data)
                })
            } catch (e) {
                if (e.response.status === 422) {
                    setLoading(false)
                }
            }
        }

    }

    const handle_export_submit = async (event) => {
        setLoading2(true)
        console.log(Items);
        exportToExcel(Items, 'SampleData');
        setLoading2(false)

    }
  
    

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.Name' }),
            accessor: 'name',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.name}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Serial' }),
            accessor: 'serial',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.serial}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Price' }),
            accessor: 'price',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.price}  
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.bbTrxRefNumber' }), accessor: 'bbTrxRefNumber',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.bbTrxRefNumber}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.RefNumber' }),
            accessor: 'RefNumber',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.RefNumber}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Date' }),
            accessor: 'date',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.date}
                </div>
            )
        },

    ];
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Reports' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Suppliers' })}</li>
                    </ul>
                </div>
            </div>
            <div className="row flex-between align-items-end mb-3" >
                <div className="col-6">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 my-2">
                            <h6>{intl.formatMessage({ id: 'Form.StartDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    value={dateState.start}
                                    onChange={([start]) => {
                                        setDateState((prevData) => ({
                                            ...prevData,
                                            start: moment(start).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 my-2">
                            <h6>{intl.formatMessage({ id: 'Form.EndDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    options={{ minDate: dateState.start }}
                                    value={dateState.end}
                                    onChange={([end]) => {
                                        setDateState((prevData) => ({
                                            ...prevData,
                                            end: moment(end).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-lg-6  col-md-6 my-2 mb-3">
                            <button onClick={handleEdit_Add_submit} type='button' className='send_btn w-100 btn btn-primary py-4' >
                                {!loading && <span className='indicator-label'>
                                    {intl.formatMessage({ id: 'Table.Search' })}
                                    <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2 ms-2' />
                                </span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        <div className="col-lg-6  col-md-6 my-2 mb-3">
                            <button onClick={handle_export_submit} type='button' className='btn btn-success py-4 w-100' >
                            {!loading2 && <span className='indicator-label'>
                                    {intl.formatMessage({ id: 'Table.Export' })}
                                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
                                </span>}
                                {loading2 && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {Items !== null && !loading &&
                <ReactTable columns={columns} data={Items} customComponent={<></>} />
            }
        </motion.nav>
    )
}

 